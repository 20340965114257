/**
 * Generated by orval v6.5.3 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import axios,{
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
export type LoadAbstractParams = { terms?: string[]; beta?: boolean };

export type DownloadGrantsParams = { org: string; start?: number; end?: number; divisions?: string[]; match?: string[]; terms?: string[]; sort?: string; order?: string; intersection?: boolean };

export type LoadGrantsParams = { idx: number; org: string; start?: number; end?: number; divisions?: string[]; match?: string[]; terms?: string[]; sort?: string; order?: string; intersection?: boolean };

export type LoadTermCounts200 = {[key: string]: number};

export type LoadTermCountsParams = { org: string; match?: string[]; terms?: string[] };

export type LoadRelatedParams = { terms?: string[] };

export type LoadTypeaheadParams = { selected_terms?: string[] };

export type YearsParams = { org: string; terms?: string[]; divisions?: string[]; match?: string[]; intersection?: boolean };

export type SearchParams = { org: string; start?: number; end?: number; terms?: string[]; match?: string[]; intersection?: boolean };

export interface YearDivisionAggregate {
  key: number;
  count: number;
  amount: number;
  divisions: DivisionAggregate[];
}

export interface YearAggregate {
  key: number;
  count: number;
  amount: number;
}

export interface YearsResponse {
  per_year: YearAggregate[];
}

export interface ValidationError {
  loc: string[];
  msg: string;
  type: string;
}

export interface TermTopic {
  term: string;
  count: number;
}

export interface Topic {
  terms: TermTopic[];
}

export interface Term {
  term: string;
  stem: string;
  forms: string[];
}

export interface SubDirectory {
  abbr: string;
  name: string;
  href: string;
  est?: number;
  desc?: string;
}

export interface SearchResponse {
  bars: YearDivisionAggregate[];
  divisions: DirectoryAggregate[];
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export interface Grant {
  id: string;
  grant_id: string;
  agency: string;
  score: number;
  title: string;
  date: string;
  amount: number;
  abstract?: string;
  cat1: string;
  cat1_raw: string;
  cat2?: string;
  cat2_raw?: string;
  cat3?: string;
  cat3_raw?: string;
  external_url?: string;
  investigators?: string;
  recipient_org?: string;
}

export interface DivisionAggregate {
  key: string;
  count: number;
  amount: number;
}

export interface Division {
  key: string;
  name: string;
  selected: boolean;
}

export interface DirectoryAggregate {
  key: string;
  count: number;
  amount: number;
  divisions: DivisionAggregate[];
}

export interface Directory {
  abbr: string;
  name: string;
  href: string;
  est?: number;
  desc?: string;
  departments: SubDirectory[];
}



// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


/**
 * @summary Divisions
 */
export const loadDivisions = (
    org: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Division[]>> => {
    return axios.get(
      `/divisions/${org}`,options
    );
  }


export const getLoadDivisionsQueryKey = (org: string,) => [`/divisions/${org}`];

    
export const useLoadDivisions = <TData = AsyncReturnType<typeof loadDivisions>, TError = AxiosError<HTTPValidationError>>(
 org: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof loadDivisions>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getLoadDivisionsQueryKey(org);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof loadDivisions>> = () => loadDivisions(org, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof loadDivisions>, TError, TData>(queryKey, queryFn, {enabled: !!(org), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Get Topics
 */
export const getTopics = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Topic[]>> => {
    return axios.get(
      `/topics`,options
    );
  }


export const getGetTopicsQueryKey = () => [`/topics`];

    
export const useGetTopics = <TData = AsyncReturnType<typeof getTopics>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getTopics>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetTopicsQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getTopics>> = () => getTopics(axiosOptions);

  const query = useQuery<AsyncReturnType<typeof getTopics>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Divisions
 */
export const loadDirectory = (
    org: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Directory[]>> => {
    return axios.get(
      `/directory/${org}`,options
    );
  }


export const getLoadDirectoryQueryKey = (org: string,) => [`/directory/${org}`];

    
export const useLoadDirectory = <TData = AsyncReturnType<typeof loadDirectory>, TError = AxiosError<HTTPValidationError>>(
 org: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof loadDirectory>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getLoadDirectoryQueryKey(org);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof loadDirectory>> = () => loadDirectory(org, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof loadDirectory>, TError, TData>(queryKey, queryFn, {enabled: !!(org), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Search
 */
export const search = (
    params?: SearchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchResponse>> => {
    return axios.get(
      `/search`,{
        params,
    ...options}
    );
  }


export const getSearchQueryKey = (params?: SearchParams,) => [`/search`, ...(params ? [params]: [])];

    
export const useSearch = <TData = AsyncReturnType<typeof search>, TError = AxiosError<HTTPValidationError>>(
 params?: SearchParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof search>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getSearchQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof search>> = () => search(params, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof search>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Years
 */
export const years = (
    params?: YearsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<YearsResponse>> => {
    return axios.get(
      `/years`,{
        params,
    ...options}
    );
  }


export const getYearsQueryKey = (params?: YearsParams,) => [`/years`, ...(params ? [params]: [])];

    
export const useYears = <TData = AsyncReturnType<typeof years>, TError = AxiosError<HTTPValidationError>>(
 params?: YearsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof years>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getYearsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof years>> = () => years(params, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof years>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Typeahead
 */
export const loadTypeahead = (
    prefix: string,
    params?: LoadTypeaheadParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Term[]>> => {
    return axios.get(
      `/keywords/typeahead/${prefix}`,{
        params,
    ...options}
    );
  }


export const getLoadTypeaheadQueryKey = (prefix: string,
    params?: LoadTypeaheadParams,) => [`/keywords/typeahead/${prefix}`, ...(params ? [params]: [])];

    
export const useLoadTypeahead = <TData = AsyncReturnType<typeof loadTypeahead>, TError = AxiosError<HTTPValidationError>>(
 prefix: string,
    params?: LoadTypeaheadParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof loadTypeahead>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getLoadTypeaheadQueryKey(prefix,params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof loadTypeahead>> = () => loadTypeahead(prefix,params, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof loadTypeahead>, TError, TData>(queryKey, queryFn, {enabled: !!(prefix), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Related
 */
export const loadRelated = (
    params?: LoadRelatedParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Term[]>> => {
    return axios.get(
      `/keywords/related`,{
        params,
    ...options}
    );
  }


export const getLoadRelatedQueryKey = (params?: LoadRelatedParams,) => [`/keywords/related`, ...(params ? [params]: [])];

    
export const useLoadRelated = <TData = AsyncReturnType<typeof loadRelated>, TError = AxiosError<HTTPValidationError>>(
 params?: LoadRelatedParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof loadRelated>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getLoadRelatedQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof loadRelated>> = () => loadRelated(params, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof loadRelated>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Count Terms
 */
export const loadTermCounts = (
    params?: LoadTermCountsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LoadTermCounts200>> => {
    return axios.get(
      `/keywords/count`,{
        params,
    ...options}
    );
  }


export const getLoadTermCountsQueryKey = (params?: LoadTermCountsParams,) => [`/keywords/count`, ...(params ? [params]: [])];

    
export const useLoadTermCounts = <TData = AsyncReturnType<typeof loadTermCounts>, TError = AxiosError<HTTPValidationError>>(
 params?: LoadTermCountsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof loadTermCounts>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getLoadTermCountsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof loadTermCounts>> = () => loadTermCounts(params, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof loadTermCounts>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Grant Data
 */
export const loadGrants = (
    params?: LoadGrantsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Grant[]>> => {
    return axios.get(
      `/grants`,{
        params,
    ...options}
    );
  }


export const getLoadGrantsQueryKey = (params?: LoadGrantsParams,) => [`/grants`, ...(params ? [params]: [])];

    
export const useLoadGrants = <TData = AsyncReturnType<typeof loadGrants>, TError = AxiosError<HTTPValidationError>>(
 params?: LoadGrantsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof loadGrants>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getLoadGrantsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof loadGrants>> = () => loadGrants(params, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof loadGrants>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Grant Download
 */
export const downloadGrants = (
    params?: DownloadGrantsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.get(
      `/grants/download`,{
        params,
    ...options}
    );
  }


export const getDownloadGrantsQueryKey = (params?: DownloadGrantsParams,) => [`/grants/download`, ...(params ? [params]: [])];

    
export const useDownloadGrants = <TData = AsyncReturnType<typeof downloadGrants>, TError = AxiosError<HTTPValidationError>>(
 params?: DownloadGrantsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof downloadGrants>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getDownloadGrantsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof downloadGrants>> = () => downloadGrants(params, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof downloadGrants>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Get Abstract
 */
export const loadAbstract = (
    id: unknown,
    params?: LoadAbstractParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Grant>> => {
    return axios.get(
      `/abstract/${id}`,{
        params,
    ...options}
    );
  }


export const getLoadAbstractQueryKey = (id: unknown,
    params?: LoadAbstractParams,) => [`/abstract/${id}`, ...(params ? [params]: [])];

    
export const useLoadAbstract = <TData = AsyncReturnType<typeof loadAbstract>, TError = AxiosError<HTTPValidationError>>(
 id: unknown,
    params?: LoadAbstractParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof loadAbstract>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getLoadAbstractQueryKey(id,params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof loadAbstract>> = () => loadAbstract(id,params, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof loadAbstract>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Send Api Json
 */
export const sendApiJsonGenerateOpenapiJsonGet = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.get(
      `/generate_openapi_json`,options
    );
  }


export const getSendApiJsonGenerateOpenapiJsonGetQueryKey = () => [`/generate_openapi_json`];

    
export const useSendApiJsonGenerateOpenapiJsonGet = <TData = AsyncReturnType<typeof sendApiJsonGenerateOpenapiJsonGet>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof sendApiJsonGenerateOpenapiJsonGet>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getSendApiJsonGenerateOpenapiJsonGetQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof sendApiJsonGenerateOpenapiJsonGet>> = () => sendApiJsonGenerateOpenapiJsonGet(axiosOptions);

  const query = useQuery<AsyncReturnType<typeof sendApiJsonGenerateOpenapiJsonGet>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


